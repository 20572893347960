// DEV
const URL = process.env.REACT_APP_API_ENDPOINT

const axios = require('axios');

export function getAuthToken(username, password) {

    let request_url = URL + "auth/login"

    return axios.post(request_url, {
        username: username,
        password: password
    },
    )
        .then(response => response.data)
        .catch(error => console.log(error))

}

export function sendSpotted(formData, id) {
    let request_url = URL + "community/add-spotted/" + id;

    return axios.post(request_url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    .then(response => response.data)
    .catch(error => {
        throw error;
    });
}

export function getAllComunities(token) {

    return axios.get(URL + 'community/get-all-communities', {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(response => response.data)
        .catch(error => console.log(error))

}

export function getCommunityByName(spotted_name) {

    let request_url = URL + "community/get-community-by-name/" + spotted_name

    return axios.get(request_url)
        .then(response => response.data)
}

export function isHCaptchaTokenValid(token) {

    let request_url = URL + "community/is-hcaptcha-token-valid"

    return axios.post(request_url, token, { headers: { 'Content-Type': 'application/json' } })
        .then(response => response.data)
        .catch(error => console.log(error))

}

export function approveSpotted(spottedId, communityId, token) {
    let request_url = URL + "community/approve-spotted/" + communityId + "/" + spottedId

    return axios.put(request_url, {/* null body */ },
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.data)
        .catch(error => console.log(error))
}



export function rejectSpotted(spottedId, communityId,reason, token) {
    let request_url = URL + "community/reject-spotted/" + communityId + "/" + spottedId + "/"+ reason 

    return axios.put(request_url, {/* null body */ },
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.data)
        .catch(error => console.log(error))
}
export function setSpottedToReview(spottedId, communityId, token) {
    let request_url = URL + "community/set-spotted-to-review/" + communityId + "/" + spottedId

    return axios.put(request_url, {/* null body */ },
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.data)
        .catch(error => console.log(error))
}

export function getAllSpottedsToApprove(communityId, token) {
    let request_url = URL + 'community/get-to-approve-spotteds-by-community/' + communityId

    return axios.get(request_url,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.data)
        .catch(error => console.log(error))

}

export function getInstaQuotaByCommunity(communityId, token) {
    let request_url = URL + 'community/get-insta-quota-by-community/' + communityId

    return axios.get(request_url,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.data)
        .catch(error => console.log(error))

}