import React, { useState, useEffect, useRef } from 'react'
import { Button, Input } from "reactstrap";
import './Spotted.css'
import axios from "axios";
import { sendSpotted, getCommunityByName, isHCaptchaTokenValid } from '../../utils/api_requests';
import { useParams, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Helmet from 'react-helmet';
import imagem from './story-4_prioridade.png';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function Spotted(props) {

    // =======================================================
    //                        SETUPS
    // =======================================================

    const captchaToken = process.env.REACT_APP_CAPTCHA_TOKEN
    const navigate = useNavigate();
    const { spotted_name } = useParams();
    const [community, setCommunity] = useState({});
    const [loading, setLoading] = useState(true);


    // =======================================================
    //            SPOTTED HOOKS & FUNCTIONS
    // =======================================================

    const [IP, setIP] = useState();

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, []);

    useEffect(() => {

        getCommunityByName(spotted_name)
            // In case it finds a page with that name
            .then((res) => {
                setLoading(false);
                setCommunity(res);
            })
            // In case it doesn't find a page with that name
            .catch((e) => navigate('/404'))
    }, []);

    // =======================================================

    // =======================================================
    //            SPOTTED HOOKS & FUNCTIONS
    // =======================================================

    const [spottedMessage, setSpottedMessage] = useState("");
    const [spottedStatus, setSpottedStatus] = useState("nenhum");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const captchaRef = useRef(null);

    // Image upload states
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 90,
        aspect: 1,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) { // 5MB limit
                setError(true);
                setErrorMessage("Erro - A imagem deve ter menos que 5MB");
                return;
            }
            if (!file.type.startsWith('image/')) {
                setError(true);
                setErrorMessage("Erro - Por favor selecione uma imagem válida");
                return;
            }
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
            setError(false);
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
        // Reset file input
        const fileInput = document.getElementById('imageInput');
        if (fileInput) fileInput.value = '';
    };

    const onImageLoad = (e) => {
        imgRef.current = e.target;
    };

    const getCroppedImg = async (image, crop) => {
        if (!crop || !image) return null;
        
        // Create a temporary canvas for the cropped area
        const tempCanvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        tempCanvas.width = crop.width;
        tempCanvas.height = crop.height;
        const tempCtx = tempCanvas.getContext('2d');

        // Draw the cropped area to the temporary canvas
        tempCtx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // Create the final 1080x1080 canvas
        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = 1080;
        finalCanvas.height = 1080;
        const finalCtx = finalCanvas.getContext('2d');

        // Enable smooth scaling
        finalCtx.imageSmoothingEnabled = true;
        finalCtx.imageSmoothingQuality = 'high';

        // Draw the temporary canvas onto the final canvas, scaling it to 1080x1080
        finalCtx.drawImage(
            tempCanvas,
            0,
            0,
            crop.width,
            crop.height,
            0,
            0,
            1080,
            1080
        );

        return new Promise((resolve) => {
            finalCanvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = 'cropped.jpeg';
                const croppedImageUrl = URL.createObjectURL(blob);
                resolve({ url: croppedImageUrl, blob });
            }, 'image/jpeg', 0.95); // Using 0.95 quality for a good balance of quality and file size
        });
    };

    const handleCropComplete = async () => {
        if (!imgRef.current || !completedCrop) return;

        try {
            const croppedImageResult = await getCroppedImg(
                imgRef.current,
                completedCrop
            );
            
            if (croppedImageResult) {
                setImagePreview(croppedImageResult.url);
                setSelectedImage(new File([croppedImageResult.blob], 'cropped.jpeg', { type: 'image/jpeg' }));
                setShowCropModal(false);
            }
        } catch (e) {
            console.error('Error cropping image:', e);
        }
    };

    const cropModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '500px',
            padding: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
    };

    function handleEnviar() {

        if (community.mobileEnabled) {
            handleOpenModal();
        } else {
            handleSendSpotted();
        }

    }

    function handleSendSpotted() {
        if (spottedMessage.trim() === "") {
            setErrorMessage("Erro - O spotted não pode ser vazio");
            setError(true);
        }
        else if (!captchaComplete) {
            setError(true);
            setErrorMessage("Erro - Captcha inválido. Tente novamente mais tarde");
        }
        else {
            setSpottedStatus("enviando");

            // Create FormData to send both text and image
            const formData = new FormData();
            formData.append('Description', spottedMessage);
            formData.append('RemoteIpAddress', IP);
            formData.append('CaptchaToken', token);
            if (selectedImage) {
                formData.append('Image', selectedImage);
            }

            sendSpotted(formData, community.id)
                .then(res => {
                    setSpottedStatus("enviado");
                })
                .catch(() => {
                    setError(true);
                    setErrorMessage("Erro - Ocorreu um erro ao enviar o spotted. Tente novamente mais tarde");
                    setSpottedStatus("nenhum");
                    captchaRef.current.resetCaptcha();
                });
            setError(false);
        }
    }


    function handleNovoSpotted() {
        setSpottedStatus("nenhum");
        setSpottedMessage("");
        setCaptchaValid(false);
        setSelectedImage(null);
        setImagePreview(null);
    }

    // =======================================================

    // =======================================================
    //                      CAPTCHA
    // =======================================================

    const [captchaValid, setCaptchaValid] = useState(false);

    const [token, setToken] = useState("");

    const [captchaComplete, setCaptchaComplete] = useState(false);

    // =======================================================

    // =======================================================
    //                         NPS
    // =======================================================

    let i_array = Array.from({ length: 10 }, (_, i) => i + 1);

    const NPS_buttons = i_array.map((i, index) => {
        let url = "https://docs.google.com/forms/d/e/1FAIpQLSf8KtvJVJWH6JmZJ8z_hw-0wATg4E7Tn6RqFn5epfApb2QCHQ/viewform?usp=pp_url&entry.442941311="
        url = url + (community != null ? community.name : "") + "&entry.1744167647=" + String(i)
        return <a href={url} target="_blank" className="NPSNotasButton">{String(i)}</a>;
    });



    // ==============================================================
    //                          MODAL
    // ==============================================================
    Modal.setAppElement('#root');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        console.log("Caiu")
        setIsModalOpen(true);
    };

    const handleCloseModal = (send = false) => {
        setIsModalOpen(false);
        if (send == true) {            
            handleSendSpotted()
        }
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            padding: '0px',
            maxWidth: '400px',
            //height: 'min-content',
            width: '90%',
        },
        
        image: {
            maxWidth: '100%',
        },

        
        buttonsContainer: {
            width: '100%',
            position: 'absolute', // Botões com posicionamento absoluto
            bottom: '10px', // Distância da parte inferior do modal
            left: '50%', // Centralizar horizontalmente
            transform: 'translateX(-50%)', // Centralizar horizontalmente
            textAlign: 'center',
        },

        app_button: {
            width: "95%",
            margin: '3px',
            padding: '10px 10px',
            borderRadius: '15px',
            backgroundColor: '#FF033E',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            outline: 'none',
        },
        button: {
            width: "95%",
            margin: '5px 5px',
            'border-color': 'transparent',
            padding: "8px",
            borderRadius: '15px',
            backgroundColor: 'white',
            color: '#fff',
            cursor: 'pointer',
            color: 'black'
        },
    };

    const handleLinkClick = () => {
        // URL para o qual você deseja redirecionar
        const url = 'https://loja.spotted.com.br';
        // Abrir o link em uma nova aba
        window.open(url, '_blank');
      };

    // =======================================================
    //                       RETURN
    // =======================================================

    return (
        <div>
            <Helmet>
                <title>Spotted - {spotted_name}</title>
                <meta name="description" content="Nossa vontade é conectar pessoas e criar uma comunidade unida, tendo como base o respeito, em primeiro lugar." />
            </Helmet>


            <div className="modal">
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => handleCloseModal(false)}
                    contentLabel="Modal de exemplo"
                    style={customStyles}
                >
                    <img src={imagem}  style={customStyles.image}></img>
                    <div style={customStyles.buttonsContainer}>
                        {/* <button style={customStyles.button} onClick={fecharModal}>
                            Fechar
                        </button> */}
                        <div>
                            <button style={customStyles.button} onClick={() => handleCloseModal(true)}>Continuar para o envio</button>
                            <button style={{ ...customStyles.app_button }} onClick={handleLinkClick}>
                                Baixar aplicativo
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>


            <div className="Spotted">
                {!loading ?
                        
                        <div className="SpottedComponent">
                            <img src={community != null ? community.logoUrl : ""} alt="spotted-community-logo" className="spottedCommunityImg" height="180px" width="180px" style={{ 'border': 'solid 2px gray' }} />
                        <h4 className="bemvindoLabel" style={{ 'marginTop': '10px' }}> Bem vindx ao {community != null ? community.readName : ""} </h4>
                        <br />
                        {(spottedStatus === "nenhum" || spottedStatus === "enviando") ?
                            <div className="sendSpottedBox">
                                

                                <Input
                                    placeholder="Deixe seu recado para o crush aqui... (max. 500 caracteres)"
                                    type="textarea"
                                    className="spottedInput"
                                    maxLength="500"
                                    value={spottedMessage}
                                    onChange={(e) => { setSpottedMessage(e.target.value) }}
                                />

                                <br />
                                
                                {community.allowImages ? <div className="imageUploadSection">
                                    <Input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />

                                    {(!selectedImage && community.allowImages) ? 
                                    <Button
                                        color="spotted"
                                        type="button"
                                        className="imageUploadButton"
                                        onClick={() => document.getElementById('imageInput').click()}
                                    >
                                        { 'Adicionar Imagem'}
                                    </Button> : null}
                                    
                                    {imagePreview && !showCropModal && (
                                        <div className="imagePreviewContainer" style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '10px',
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            marginTop: '10px',
                                            backgroundColor: '#fff',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            maxWidth: '500px'
                                        }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img 
                                                    src={imagePreview} 
                                                    alt="Preview" 
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        objectFit: 'cover',
                                                        borderRadius: '4px'
                                                    }}
                                                />
                                                <span style={{ marginLeft: '10px', color: '#666' }}>Imagem anexada</span>
                                            </div>
                                            <button
                                                onClick={removeImage}
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: '#ff4444',
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    padding: '5px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    )}

                                    <Modal
                                        isOpen={showCropModal}
                                        onRequestClose={() => setShowCropModal(false)}
                                        style={cropModalStyles}
                                        contentLabel="Recortar Imagem"
                                    >
                                            <p style={{ 'width': '300px', 'textAlign': 'center', 'fontSize': '12px', 'marginTop': '10px' }}> 
                                                Spotteds com imagem podem demorar mais para serem aprovados e publicados =)                                            
                                            </p>
                                        <div style={{ width: '100%', maxHeight: '500px', overflow: 'auto' }}>
                                            <ReactCrop
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={(c) => setCompletedCrop(c)}
                                                aspect={1}
                                            >
                                                <img
                                                    ref={imgRef}
                                                    alt="Crop me"
                                                    src={imagePreview}
                                                    onLoad={onImageLoad}
                                                    style={{ maxWidth: '100%' }}
                                                />
                                            </ReactCrop>
                                        </div>
                                        <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                                            <Button
                                                color="spotted"
                                                onClick={handleCropComplete}
                                            >
                                                Confirmar
                                            </Button>
                                            <Button
                                                color="secondary"
                                                onClick={() => {setShowCropModal(false);removeImage()}}
                                            >
                                                Cancelar
                                            </Button>
                                        </div>
                                    </Modal>
                                </div> : null}

                                <br />

                                <HCaptcha className="hcaptchaButton" ref={captchaRef} sitekey={captchaToken} onVerify={(e) => { setToken(e); setCaptchaComplete(true) }} />
                                {/* <div style={{'height':'85px', 'width':'350px', 'border':'solid 2px'}} /> */}
                                <br />

                                {error ?
                                    <div style={{ "width": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center" }}>
                                        <div class="alert alert-info spottedAlert" style={{ 'textAlign': 'center' }} role="alert">
                                            <b>{errorMessage}</b>
                                        </div>
                                        <br />
                                    </div>
                                    :
                                    <span />
                                }

                                <Button color="spotted" type="button" className="spottedButton" onClick={(e) => { handleEnviar() }}>
                                    {spottedStatus === "enviando" ? <i className="fa fa-spinner fa-spin" style={{ 'marginRight': '10px', 'fontSize': '20px', 'verticalAlign': 'middle' }} /> : <span />}
                                    Enviar
                                </Button>
                                <p style={{ 'width': '300px', 'textAlign': 'center', 'fontSize': '12px', 'marginTop': '10px' }}> Atenção: devido a spotteds recentes, passamos a recolher os IPs para casos extremos. Mesmo assim, os spotteds continuam sendo anônimos =) </p>
                            </div>
                            :

                            <div className="spottedEnviadoBox">
                                <b style={{ "fontSize": "25px" }}>Spotted enviado!</b>
                                <br />

                                <div className="gray-line"></div>
                                <b>E aí, que tal avaliar o spotted?</b>
                                <span style={{ "margin": "5px", "width": "290px", "textAlign": "center" }}>De 1 a 10, qual a chance de você recomendar a página para um amigo?</span>
                                <div className="NPSNotasSection">
                                    {NPS_buttons}
                                </div>
                                <div className="gray-line"></div>
                                <br></br>   
                            </div>

                        }
                    </div>
                    :
                    <div className="ReactLoadingBox">
                        <ReactLoading type={"spinningBubbles"} color={"#f95353"} height={125} width={125} />
                    </div>
                }
            </div>
        </div>
    )
}
